import React, { useState, useEffect } from "react"
import { LabeledTextField } from "app/core/components/LabeledTextField"
import { Form, FORM_ERROR } from "app/core/components/Form"
import login from "../mutations/login"
import { LoginInput } from "../validations"
import { Link, useMutation, AuthenticationError, useSession } from "blitz"
import SplitScreenLayout from "app/core/layouts/SplitScreenLayout"
import { GoogleOAuthProvider } from "@react-oauth/google"
import GoogleOAuth from "app/auth/components/GoogleAuth"
import { User } from "@prisma/client"

type LoginFormProps = {
  onSuccess?: (loginUser: User) => void
}

export const LoginForm = (props: LoginFormProps) => {
  const [loginMutation] = useMutation(login)
  const session = useSession({ suspense: false })
  const [googleUser, setGoogleUser] = useState(null)
  const [errorMessage, setErrorMessage] = React.useState("")

  useEffect(() => {
    if (googleUser) {
      loginMutation(googleUser)
    }
  }, [googleUser, loginMutation])

  return (
    <GoogleOAuthProvider clientId={process.env.GOOGLE_OAUTH_CLIENTID || ""}>
      <SplitScreenLayout heading="Log in to your account" className="space-y-8">
        <Form
          submitText="Log In"
          schema={LoginInput}
          initialValues={{ email: session.email || undefined, password: undefined }}
          onSubmit={async (values) => {
            try {
              const loginUser = await loginMutation(values)
              if (props.onSuccess) {
                props.onSuccess(loginUser)
              }
            } catch (error) {
              if (error instanceof AuthenticationError) {
                return { [FORM_ERROR]: "Sorry, those credentials are invalid" }
              } else {
                return {
                  [FORM_ERROR]:
                    "Sorry, we had an unexpected error. Please try again. - " + error.toString(),
                }
              }
            }
          }}
        >
          <LabeledTextField
            name="email"
            label="Email"
            placeholder="Email"
            autoComplete="email"
            type="email"
          />
          <LabeledTextField
            name="password"
            label="Password"
            placeholder="Password"
            type="password"
            autoComplete="password"
          />

          <div className="flex items-center justify-end mt-6">
            <div className="text-sm leading-5">
              <Link href="/forgot-password">
                <a className="font-medium text-primary-600 hover:text-primary-500 ">
                  Forgot your password?
                </a>
              </Link>
            </div>
          </div>
        </Form>

        <GoogleOAuth setUser={setGoogleUser} setErrorMessage={setErrorMessage} />
        {errorMessage && <div className="pl-1 text-sm text-red-700">{errorMessage}</div>}
      </SplitScreenLayout>
    </GoogleOAuthProvider>
  )
}

export default LoginForm
